































import { doPostAddFeedback } from '@/api/suggest';
import AddFeedback from './AddFeedback.vue'
export default{
  components: { AddFeedback },
  data() {
    return {
      activeName: 'suggest' as 'product' | 'suggest',
      tabsComponent: {
        product: () => import('./product.vue'),
        suggest: () => import('./suggest.vue')
      },
      showDialog: false
    }
  },
  methods: {
    async confirmFeedBack() {
      const addFeedbackRefs: InstanceType<typeof AddFeedback> | null = this.$refs.addFeedbackRefs as InstanceType<typeof AddFeedback> | null;
      const data = await addFeedbackRefs!.getCurrentForm();
      try {
        const result = await doPostAddFeedback(data);
        this.$message.success(result.msg || '提交建议成功');
        this.showDialog = false;
        if (this.activeName === 'suggest') {
          const suggestComponentRefs: any  = this.$refs.componentRefs
          suggestComponentRefs.initialData()
        }
      } catch (err) {
        this.$message.error(err as string)
      }
    },
    handleAddFeedback() {
      this.showDialog = true
    }
  }
}
